exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-page-components-article-page-index-tsx": () => import("./../../../src/components/pageComponents/ArticlePage/index.tsx" /* webpackChunkName: "component---src-components-page-components-article-page-index-tsx" */),
  "component---src-components-page-components-ask-lifestyle-article-page-index-tsx": () => import("./../../../src/components/pageComponents/Ask/Lifestyle/ArticlePage/index.tsx" /* webpackChunkName: "component---src-components-page-components-ask-lifestyle-article-page-index-tsx" */),
  "component---src-components-page-components-topics-page-topic-page-tsx": () => import("./../../../src/components/pageComponents/TopicsPage/TopicPage.tsx" /* webpackChunkName: "component---src-components-page-components-topics-page-topic-page-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-deletion-tsx": () => import("./../../../src/pages/accountDeletion.tsx" /* webpackChunkName: "component---src-pages-account-deletion-tsx" */),
  "component---src-pages-ask-lifestyle-index-tsx": () => import("./../../../src/pages/ask/lifestyle/index.tsx" /* webpackChunkName: "component---src-pages-ask-lifestyle-index-tsx" */),
  "component---src-pages-ask-qpl-index-tsx": () => import("./../../../src/pages/ask/qpl/index.tsx" /* webpackChunkName: "component---src-pages-ask-qpl-index-tsx" */),
  "component---src-pages-ask-self-assessment-index-tsx": () => import("./../../../src/pages/ask/self-assessment/index.tsx" /* webpackChunkName: "component---src-pages-ask-self-assessment-index-tsx" */),
  "component---src-pages-discussion-forum-edit-topic-tsx": () => import("./../../../src/pages/discussion-forum/edit-topic.tsx" /* webpackChunkName: "component---src-pages-discussion-forum-edit-topic-tsx" */),
  "component---src-pages-discussion-forum-index-tsx": () => import("./../../../src/pages/discussion-forum/index.tsx" /* webpackChunkName: "component---src-pages-discussion-forum-index-tsx" */),
  "component---src-pages-discussion-forum-start-topic-tsx": () => import("./../../../src/pages/discussion-forum/start-topic.tsx" /* webpackChunkName: "component---src-pages-discussion-forum-start-topic-tsx" */),
  "component---src-pages-discussion-forum-thread-tsx": () => import("./../../../src/pages/discussion-forum/thread.tsx" /* webpackChunkName: "component---src-pages-discussion-forum-thread-tsx" */),
  "component---src-pages-goal-setting-achieveable-slider-tsx": () => import("./../../../src/pages/goal-setting/achieveable-slider.tsx" /* webpackChunkName: "component---src-pages-goal-setting-achieveable-slider-tsx" */),
  "component---src-pages-goal-setting-add-habits-tsx": () => import("./../../../src/pages/goal-setting/add-habits.tsx" /* webpackChunkName: "component---src-pages-goal-setting-add-habits-tsx" */),
  "component---src-pages-goal-setting-choose-habits-tsx": () => import("./../../../src/pages/goal-setting/choose-habits.tsx" /* webpackChunkName: "component---src-pages-goal-setting-choose-habits-tsx" */),
  "component---src-pages-goal-setting-goal-review-tsx": () => import("./../../../src/pages/goal-setting/goal-review.tsx" /* webpackChunkName: "component---src-pages-goal-setting-goal-review-tsx" */),
  "component---src-pages-goal-setting-homepage-tsx": () => import("./../../../src/pages/goal-setting/homepage.tsx" /* webpackChunkName: "component---src-pages-goal-setting-homepage-tsx" */),
  "component---src-pages-goal-setting-importance-slider-tsx": () => import("./../../../src/pages/goal-setting/importance-slider.tsx" /* webpackChunkName: "component---src-pages-goal-setting-importance-slider-tsx" */),
  "component---src-pages-goal-setting-obstacles-tsx": () => import("./../../../src/pages/goal-setting/obstacles.tsx" /* webpackChunkName: "component---src-pages-goal-setting-obstacles-tsx" */),
  "component---src-pages-goal-setting-select-solutions-tsx": () => import("./../../../src/pages/goal-setting/select-solutions.tsx" /* webpackChunkName: "component---src-pages-goal-setting-select-solutions-tsx" */),
  "component---src-pages-goal-setting-set-goal-metrics-tsx": () => import("./../../../src/pages/goal-setting/set-goal-metrics.tsx" /* webpackChunkName: "component---src-pages-goal-setting-set-goal-metrics-tsx" */),
  "component---src-pages-goal-setting-solutions-tsx": () => import("./../../../src/pages/goal-setting/solutions.tsx" /* webpackChunkName: "component---src-pages-goal-setting-solutions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-log-in-tsx": () => import("./../../../src/pages/log-in.tsx" /* webpackChunkName: "component---src-pages-log-in-tsx" */),
  "component---src-pages-log-out-tsx": () => import("./../../../src/pages/log-out.tsx" /* webpackChunkName: "component---src-pages-log-out-tsx" */),
  "component---src-pages-my-dashboard-tsx": () => import("./../../../src/pages/my-dashboard.tsx" /* webpackChunkName: "component---src-pages-my-dashboard-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-topics-tsx": () => import("./../../../src/pages/topics.tsx" /* webpackChunkName: "component---src-pages-topics-tsx" */)
}

